@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&family=Open+Sans:wght@300&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}

:root {
  font-family: 'Open Sans', sans-serif;
}